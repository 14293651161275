

















import { Component, Vue } from 'vue-property-decorator'

@Component
export default class UseTerms extends Vue {
  text = `TERMO DE USO DO SISTEMASBQ E POLÍTICA DE PRIVACIDADE
      A SOCIEDADE BRASILEIRA DO QUADRIL (“SBQ”) estabelece neste Termo de Uso e Política de Privacidade (“TERMO”) as condições para utilização do SISTEMASBQ, de forma a esclarecer as obrigações e responsabilidades de seus usuários. Leia atentamente o seguinte TERMO, pois ele se aplica ao seu acesso e forma de uso das informações, materiais e itens/funções contidas no SISTEMASBQ. Ao acessar o SISTEMASBQ, você estará concordando e declarando estar ciente com este TERMO.
      1. DEFINIÇÕES
      Para os fins deste TERMO, consideram-se: i) Endereço de Protocolo de Internet (Endereço IP): o código atribuído a um Terminal de uma rede para permitir sua identificação, definido segundo parâmetros internacionais. ii) USUÁRIO: associado da SBQ quite com a anuidade, que se habilitará no SISTEMASBQ, via cadastro, responsabilizando-se pelos dados e informações obtidas, definido como USUÁRIO do SISTEMASBQ iii) CADASTRO: registro com os dados pessoais e profissionais do USUÁRIO, obrigatoriamente preenchido com o Login e a Senha do USUÁRIO para a utilização dos serviços SISTEMASBQ. Para a utilização das ferramentas disponíveis no SISTEMASBQ; iv) LOGIN: trata-se do CPF, informado pelo USUÁRIO no ato do seu CADASTRO, que usará para acessar o SISTEMASBQ e o seu CADASTRO; v) SENHA: sequência de letras e números escolhida pelo USUÁRIO, composta de no mínimo 8 caracteres, a qual deverá ser informada pelo USUÁRIO quando do acesso ao SISTEMASBQ. vi) SISTEMASBQ: aplicativo da SBQ por meio do qual o Usuário acessa os conteúdos disponibilizados pela SBQ, SISTEMASBQ; vii) SITE: portal eletrônico do SISTEMASBQ, localizado no endereço https://SISTEMASBQ.com.br seus subdomínios, por meio do qual o USUÁRIO poderá acessar o SISTEMASBQ e solicitar a disponibilização do SOFTWARE, mediante preenchimento de CADASTRO, informação de LOGIN e SENHA de acesso próprio.

      2. DO OBJETO
      2.1 Este Termo de Uso apresenta as condições gerais aplicáveis ao uso dos serviços oferecidos pela SBQ por meio do SISTEMASBQ.
      2.2. Constitui objeto do presente Termo a disponibilização gratuita aos associados quites do SISTEMASBQ – que tem por objetivo auxiliar o USUÁRIO a coletar, organizar dados, exames de imagem, informações de seus pacientes e seus históricos, mediante o LOGIN e SENHA, além do gerenciamento do perfil do USUÁRIO no SISTEMASBQ.
      2.3. O SISTEMASBQ, possibilita cadastro, armazenamento de dados clínicos, organização das informações médicas dos pacientes do Médico USUÁRIO, procedimentos realizados, próteses implantadas, sua categorização e visualização de relatórios.

      2.4. O SISTEMASBQ não é fornecedor de quaisquer informações cadastradas pelo USUÁRIO no seu LOGIN no SISTEMASBQ e nem intervém na inserção das referidas informações. O USUÁRIO ainda poderá acessar outros conteúdos de apoio, e utilizar outros serviços oferecidos pelo próprio SISTEMASBQ ou por terceiros, parceiros do SISTEMASBQ.

      2.5. O SISTEMASBQ é acessível exclusivamente à partir do endereço https://SISTEMASBQ.com.br

      2.6. Em que pese ser passível de utilização a partir de qualquer parte do mundo, o USUÁRIO declara-se ciente e concorda que, independentemente do local de onde esteja sendo acessados os serviços, a relação jurídica entre as partes, e em qualquer hipótese, ficará sujeita à legislação brasileira.

      2.7. O USUÁRIO desde já está ciente e concorda que ao inserir conteúdos, carregar arquivos e informações médicas e pessoais de seus pacientes no SISTEMASBQ, através de seu LOGIN, o se responsabiliza única e exclusivamente por todas as informações ali inseridas e por deter todas as licenças e/ou aprovações necessárias para a utilização do conteúdo inserido, ressaltando que sempre será necessário a autorização expressa dos pacientes para coleta, armazenamento, tratamento, e uso de suas informações médicas, e se tratando de paciente menor de idade, autorização de seu responsável legal.

      2.8. A SBQ não é proprietária ou contratante de serviços adicionais, ofertados por terceiros e/ou parceiros do SISTEMASBQ, que fazem integração com diversos sistemas, inclusive o SISTEMASBQ, e não guarda nenhuma relação empregatícia com estes, servindo apenas como um intermediário entre as informações inseridas pelos USUÁRIOS no SISTEMASBQ, não se responsabilizando pela qualidade, estado, integridade ou legitimidade das informações importadas e/ou exportadas de ou por terceiros e/ou parceiros do SISTEMASBQ. Apesar disso, a SBQ se reserva o direito de retirar de sua base de dados quaisquer USUÁRIOS e/ou parceiros que não atendam aos padrões de ética, qualidade e boa-fé do SISTEMASBQ. Portanto, o USUÁRIO do SISTEMASBQ está ciente e concorda que se as informações inseridas no SISTEMASBQ infringirem alguma norma e/ou regulamento que possa ser aplicável à referida atividade, o USUÁRIO desde já isenta a SBQ de qualquer responsabilidade por esta atividade.

      2.9. A SBQ não será responsável pelo efetivo cumprimento das obrigações assumidas pelos terceiros e/ou parceiros com os USUÁRIOS, apesar de tentar evitar quaisquer fraudes ou má-fé de terceiros e/ou parceiros. Neste sentido, o USUÁRIO reconhece e aceita que ao usar e/ou contratar serviços de parceiros/terceiros que possuam serviços de integração com o SISTEMASBQ, o faz por sua conta e risco, sendo exclusivamente de sua responsabilidade todas as obrigações dela decorrente, sejam fiscais, trabalhistas, consumeristas ou de qualquer outra natureza. Em nenhum caso, a SBQ será responsável pelo lucro cessante ou por qualquer outro dano e/ou prejuízo que o USUÁRIO possa sofrer devido à contratação destes serviços de terceiros e/ou parceiros do SISTEMASBQ.

      2.10. O SISTEMASBQ facilitará a comunicação entre os USUÁRIOS e seus respectivos pacientes, intermediando a troca de informações no SISTEMASBQ, SEMPRE PRECEDIDA PELA ANUÊNCIA ENTRE O USUÁRIO E SEU PACIENTE, mediante o aceite do presente Termo e liberação de compartilhamento dentro do SISTEMASBQ. Tais informações poderão se referir àquelas disponibilizadas no CADASTRO, informações do USUÁRIO, ou qualquer outra informação que seja disponibilizada pelo USUÁRIO para o SISTEMASBQ, exceto as informações que possuem sigilo estabelecido por lei.

      2.11. A SBQ, com o objetivo de zelar pelo bom atendimento, pela prevalência da boa-fé e dos princípios constitucionais de lealdade e segurança nas comunicações entre os USUÁRIOS e USUÁRIOS/PACIENTES, além da veracidade das informações colocadas à disposição no SISTEMASBQ, buscará retirar de sua base de USUÁRIOS, aqueles que não compactuam com estes princípios.

      3. CADASTRO E FUNCIONAMENTO DOS SERVIÇOS SBQ
      3.1. Ao utilizar o SISTEMASBQ, clicando em “Aceito os Termos de Uso” da página de cadastro, o USUÁRIO estará automaticamente aderindo e concordando em se submeter integralmente aos termos e condições e de quaisquer de suas alterações futuras, além de aceitar as disposições das políticas do presente TERMO.

      3.1.1 O Usuário se responsabiliza pela precisão e veracidade dos dados informados e reconhece que a inconsistência destes poderá implicar a impossibilidade de acessar o SISTEMASBQ.
      3.1.2. O Usuário assume inteira responsabilidade pela guarda, sigilo e boa utilização do login e senhas cadastrados, isentando a SBQ de qualquer responsabilidade.
      3.1.3. O login e Senha só poderão ser utilizados pelo Usuário cadastrado, sendo expressamente proibido o compartilhamento de login e/ou Senha com quaisquer terceiros.
      3.1.4. O Usuário poderá, a qualquer momento, excluir sua conta.

      4. PRIVACIDADE
      4.1. O SISTEMASBQ utiliza-se da “TECNOLOGIA DE COOKIES” (dados no computador/celular do USUÁRIO) para permitir sua correta identificação, além de melhorar a qualidade das informações oferecidas no SISTEMASBQ para o USUÁRIO. O uso regular de cookies é uma prática aceitável na indústria, pois permite o armazenamento de informações importantes, como, por exemplo, os acessos do USUÁRIO no SISTEMASBQ. Se não for a vontade do USUÁRIO receber cookies, ou os mesmos desejarem uma notificação prévia antes de seu uso, eles deverão programar, a seu critério, seu browser com estas opções, caso seu browser disponha do recurso. Vale ressaltar que caso o USUÁRIO realize este bloqueio, a experiência de uso será comprometida.

      4.2. Além das informações pessoais fornecidas, o SISTEMASBQ tem a capacidade tecnológica de recolher outras informações técnicas, como por exemplo, o endereço de IP do USUÁRIO, o sistema operacional do computador ou do celular e o tipo de browser.

      4.3. A SBQ não fornecerá as informações do USUÁRIO a terceiros, nos termos da Lei nº 13.709/18, com exceção de casos previstos em lei ou já previamente autorizados pelo presente Termo e para responder às solicitações ou perguntas de entidades governamentais, ou nos casos onde, de boa-fé, a SBQ entender que é necessária a sua divulgação, com o propósito de responder às reclamações em que há alegação de que o conteúdo submetido ao SISTEMASBQ infringe direitos de terceiros ou a legislação em vigor, ou seja, necessária para a proteção de direitos, propriedades e/ou segurança do SISTEMASBQ, de seus USUÁRIOS e/ou do público em geral.

      4.4. A SBQ deverá, a seu critério, examinar as informações armazenadas nos seus bancos de dados, exceto com relação aos dados protegidos por sigilo legal, com o propósito de identificar USUÁRIOS com múltiplas identidades ou pseudônimos para fins legais e/ou de segurança. Em outra hipótese, se a SBQ for obrigada por lei, ordem judicial ou outro processo legal, a divulgar alguma informação pessoal do USUÁRIO, não hesitará em cooperar com estes agentes, inclusive em relação aos dados protegidos por sigilo legal. Assim, por meio deste instrumento, o USUÁRIO autoriza a SBQ a divulgar toda e qualquer informação contida no SISTEMASBQ para atender aos fins acima indicados, desde que sejam embasados em ordens judiciais.

      4.5. As informações cedidas pelo USUÁRIO, registradas no sistema, servirão como insumos para o mapeamento de informações de mercado e formação de estatísticas do SISTEMASBQ. Através do cadastramento, uso e fornecimento de informações ao SISTEMASBQ, o USUÁRIO deliberadamente aceita o presente Termo e as condições previstas na Política de Privacidade sobre o uso de suas informações. O SISTEMASBQ ressalta que não acessará quaisquer informações de pacientes e os seus prontuários, cujo sigilo é protegido pela legislação brasileira e pelas resoluções de categoria profissional, exceto mediante ordem judicial.

      4.6. As informações cedidas pelo USUÁRIO que o tornam pessoalmente identificável têm como objetivo fazer com que os USUÁRIOS do SISTEMASBQ se relacionem melhor com as ferramentas nele disponíveis. Informações adicionais coletadas pelo SISTEMASBQ através da análise da navegação de cada USUÁRIO e que não o torne identificável pessoalmente são de propriedade exclusiva do SISTEMASBQ, que pode usar essas informações do modo que melhor julgar apropriado. Além disso, as informações fornecidas são usadas para: (i) administrar a conta dos USUÁRIOS a fim de customizar cada vez mais as ferramentas disponíveis no SISTEMASBQ; e (ii) comunicar novidades e atualizações.

      4.7. O USUÁRIO que cancelar seu CADASTRO removerá seus dados para futuras consultas, mas esta remoção não funcionará de maneira retroativa, ou seja, as ações anteriores a essa decisão continuarão armazenados e com uso de acordo com os interesses do SISTEMASBQ, sendo mantido sob absoluto sigilo os dados clínicos dos pacientes, porém, poderá utilizar dados dos relatórios obtidos, no que tange a dados demográficos, condição com a qual o USUÁRIO concorda antecipada e permanentemente.

      4.8. Não é permitida a utilização de nenhum dispositivo, software ou outro recurso que venha a interferir nas atividades e operações do SISTEMASBQ, bem como nas contas ou seus bancos de dados. Qualquer intromissão, tentativa de, ou atividade que viole ou contrarie as leis de direito de propriedade intelectual e/ou as proibições estipuladas neste Termo, tornarão o responsável passível das ações legais pertinentes, bem como das sanções aqui previstas, sendo ainda responsável pelas indenizações por eventuais danos causados.
      5. RESPONSABILIDADES
      5.1. O Usuário não poderá praticar as seguintes ações com relação ao SISTEMASBQ, no todo ou em parte, sem prejuízo de outras que sejam consideradas ilegais, contrariem a ordem pública ou atentem contra a moral e os bons costumes: i) prática de quaisquer atos ilícitos e/ou violação da legislação vigente, inclusive das disposições da Lei 9.613/98 e da Lei 12.846/13; ii) Utilizar o SISTEMASBQ ou qualquer conteúdo dele constante, no todo ou em parte, sob qualquer meio ou forma, com propósito diverso daquele a que este se destina e de forma diversa da prevista neste TERMO, inclusive divulgando, a qualquer título, a terceiros que não tenham ou não devam ter acesso ao SISTEMASBQ; iii) Apagar, deturpar, corromper, alterar, editar, adaptar, transmitir ou de qualquer forma modificar, sob qualquer meio ou forma, no todo ou em parte, o SISTEMASBQ, e/ou qualquer conteúdo dele constante; iv) Fazer publicidade ou marketing associando sua imagem do SISTEMASBQ, sem prévia autorização; v) Carregar, enviar e/ou transmitir qualquer conteúdo de cunho erótico, pornográfico, obsceno, difamatório ou calunioso ou que façam apologia ao crime, uso de drogas, consumo de bebidas alcoólicas ou de produtos fumígenos, violência física ou moral; vi) Carregar, enviar e/ou transmitir qualquer conteúdo que promova ou incite o preconceito (inclusive de origem, raça, sexo, cor, orientação sexual e idade) ou qualquer forma de discriminação, bem como o ódio ou atividades ilegais; vii) Ameaçar, coagir, ou causar constrangimento físico ou moral aos demais Usuários; viiii) Praticar quaisquer atos em relação ao SISTEMASBQ, direta ou indiretamente, no todo ou em parte, que possam causar prejuízo à SBQ, a qualquer Usuário e/ou a quaisquer terceiros; ix) Usar qualquer marca, nome de domínio, slogan ou qualquer sinal distintivo ou bem de propriedade intelectual de titularidade da SBQ.

      5.2. É de responsabilidade exclusiva do USUÁRIO velar pela legalidade de suas atividades e informações cadastradas e a SBQ não assume nenhuma responsabilidade pela existência delas no SISTEMASBQ, podendo excluir o USUÁRIO que não cumpra com as restrições descritas no presente Termo, independente de aviso prévio. Portanto, o USUÁRIO será responsável, pelas transgressões das regras que resultem em infração a direitos intelectuais de terceiros, estando a SBQ isenta de qualquer responsabilidade perante o titular destes direitos.

      5.3.. O Usuário é exclusivamente responsável: i) Por todos e quaisquer atos ou omissões por ele realizados no ambiente do SISTEMASBQ; ii) Pela reparação de todos e quaisquer danos, diretos ou indiretos, que sejam causados à SBQ, a qualquer outro Usuário, ou, ainda, a qualquer terceiro, inclusive em virtude do descumprimento do disposto neste TERMO. Sem prejuízo de outras medidas, A SBQ poderá, por si ou por terceiros, a qualquer tempo, a seu exclusivo critério, sem necessidade de qualquer aviso ou notificação prévia, suspender o acesso ao SISTEMASBQ de qualquer Usuário, a qualquer tempo, caso este descumpra qualquer disposição deste TERMO e demais políticas do SISTEMASBQ ou da legislação vigente aplicável.

      5.4.. Em nenhuma hipótese, A SBQ será responsável: i) Por qualquer ato ou omissão realizado e/ou dano causado pelo Usuário neste SISTEMASBQ; ii) Pela instalação no equipamento do Usuário ou de terceiros, de vírus, trojans, malware, worm, bot, backdoor, spyware, rootkit, ou de quaisquer outros dispositivos que venham a ser criados, em decorrência da navegação na Internet pelo Usuário. iii) Pelo uso indevido por qualquer usuário ou terceiros do SISTEMASBQ, no todo ou em parte, por qualquer meio ou forma, incluindo, mas não se limitando à reprodução e/ou divulgação em quaisquer meios; iv) Pela suspensão, interrupção ou remoção do SISTEMASBQ.

      5.5. A SBQ não se responsabiliza por qualquer dano, prejuízo ou perda no equipamento do USUÁRIO causado por falhas no sistema, no servidor, provedor ou na internet decorrentes de condutas de terceiros, caso fortuito ou força maior. Também não será responsável por qualquer vírus que possa atacar o equipamento do USUÁRIO em decorrência do acesso, utilização ou navegação no SISTEMASBQ em virtude da internet decorrentes de condutas de terceiros, caso fortuito ou força maior; ou como consequência da transferência de dados. Os USUÁRIOS não poderão atribuir ao SISTEMASBQ nenhuma responsabilidade nem exigir o pagamento por lucro cessante em virtude de prejuízos resultantes de dificuldades técnicas ou falhas nos sistemas ou na internet decorrentes de condutas de terceiros, caso fortuito ou força maior. Eventualmente, o SISTEMASBQ poderá não estar disponível por motivos técnicos ou falhas da internet, ou por qualquer outro evento fortuito ou de força maior alheio ao controle do SISTEMASBQ.

      5.6. O SISTEMASBQ tem como objetivo auxiliar a equipe médica a organizar e gerenciar seus conhecimentos referentes as cirurgias e disponibiliza as ferramentas necessárias para o USUÁRIO atuar de forma tranquila em seu dia a dia. Sendo assim, a SBQ não se responsabiliza pelas obrigações tributárias que recaiam sobre as atividades do USUÁRIO.

      6. DISPOSIÇÕES GERAIS.
      6.1. A SBQ não assume nenhuma responsabilidade por nenhum website acessado a partir deste SISTEMASBQ.

      6.2. Um link deste SISTEMASBQ para outro site não constitui, necessariamente, uma recomendação, patrocínio, aprovação, propaganda ou oferta.

      6.3. A tolerância quanto ao eventual descumprimento de quaisquer das disposições deste TERMO deste SISTEMASBQ por qualquer Usuário não constituirá renúncia ao direito de exigir o cumprimento da obrigação.

      6.4. A SBQ poderá, a qualquer tempo, a seu exclusivo critério e sem necessidade de qualquer aviso prévio: i) Tirar o SISTEMASBQ do ar; ii) Alterar e/ou atualizar no todo ou em parte este TERMO iii) Alterar e/ou atualizar as políticas presentes no SISTEMASBQ,

      6.5. A SBQ reserva a si o direito de reexaminar e corrigir os termos e condições desse acordo a qualquer momento. Qualquer correção será obrigatória e válida imediatamente após a postagem do acordo modificado em no SISTEMASBQ. A continuidade do uso do site implica em concordância com qualquer alteração nos termos e condições e deverá ser integralmente observada pelos Usuários.

      6.6. A SBQ reserva a si o direito de reexaminar e corrigir os termos e condições desse acordo a qualquer momento. Qualquer correção será obrigatória e válida imediatamente após a postagem do acordo modificado em no SISTEMASBQ. A continuidade do uso do site implica em concordância com qualquer alteração nos termos e condições e deverá ser integralmente observada pelos Usuários.

      6.7. O Usuário está ciente e concorda com a coleta, armazenamento, tratamento, processamento e uso das Informações enviadas e/ou transmitidas pelo Usuário nos termos aqui estabelecidos.

      6.8. A SBQ poderá, a qualquer momento, alterar as disposições constantes neste termo, se comprometendo a dar a devida publicidade às modificações efetivadas mediante a disponibilização de avisos e alertas no SISTEMASBQ.

      6.9. O USUÁRIO deverá cumprir todas as leis, regulamentações e políticas que estejam em vigor no território brasileiro, especificamente a lei 12.846 de 1º de agosto de 2013, que se aplica às suas atividades relacionadas à ética e integridade.

      6.10. A SBQ poderá divulgar notificações ou mensagens através do SISTEMASBQ para informar os Usuários a respeito de mudanças nos serviços ou no Termo de Uso, Navegação, Política, Privacidade e Compartilhamento, ou outros assuntos relevantes. Este TERMO é regido de acordo com a legislação brasileira. Quaisquer disputas ou controvérsias oriundas de quaisquer atos praticados no âmbito do SISTEMASBQ pelos Usuários, inclusive com relação ao descumprimento deste TERMO, e/ou demais políticas do SISTEMASBQ, serão processadas na Comarca da Capital do Estado de São Paulo. "DECLARO QUE LI E CONCORDO COM O TERMO DE USO APRESENTADO"`
}
